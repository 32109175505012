import React from "react";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Home from './pages/Home';
import Faq from './pages/Faq';
import { motion } from "framer-motion";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 0;

  h1 {
    margin: 0;
    font-family: "Merriweather";
  }

  p {
    color: #fff3e3;
  }

  p a {
    color: #8db9d4;
  }
`;

export const Button = styled.button`
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 10px;
  background-color: #1c3e5f;
  color: #fff3e3;
  cursor: pointer;
  margin: 20px;
  font-family: "Karla";
  transition: background-color 0.2s;

  &:hover {
    background-color: #234f79;
  }
`;


function App() {
  return (
    <Container>
      <motion.h1>Phineas Walton</motion.h1>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/faq" component={Faq} />
        </Switch>
      </Router>
    </Container>
  );
}

export default App;
