import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  footer {
    margin-top: 15px;
    font-size: 0.6rem;
  }
`;

const Personal = styled.span`
  color: #968874;
  margin: 15px;

  a {
    color: #fff3e3;
    border-bottom: 1px solid #968874;
    text-decoration: none;
  }
`;

const Home = () => (
  <Wrapper>
    <p>
      Hey 👋 I'm Phin. I write $5-10k angel checks into ~10 seed-stage startups
      every year.
      <br />I offer expertise in product development, design, strategy & more.
    </p>

    <p>
      Feel free to{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://twitter.com/messages/compose?recipient_id=102583719"
      >
        DM me on Twitter
      </a>
      .
    </p>

    <Personal>
      Looking for my <a href="https://phineas.io">personal website?</a>
    </Personal>

    <footer>&copy; Catgirl Capital, LLC</footer>
  </Wrapper>
);

export default Home;
