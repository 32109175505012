import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../App";

const Wrapper = styled.div`
  margin-top: 20px;
  text-align: left;
  max-width: 600px;
  display: flex;
  flex-direction: column;

  h1 {
    font-family: "Karla";
  }

  a {
    align-self: center;
    text-align: center;
  }
`;

export const Faq = () => (
  <Wrapper>
    <Link to="/">
      <Button>back</Button>
    </Link>
    <h1>what am I interested in?</h1>
    <p>
      v interested in consumer social apps and "b2d" (business to developer)
      services that make developers lives easier - I'm less interested in b2b &
      dtc companies!
    </p>

    <h1>what's the process?</h1>
    <p>
      if your company looks like a fit:
      <ul>
        <li>due dilly ({"<2"} days)</li>
        <li>funds wired within a week</li>
      </ul>
    </p>

    <h1>investment vehicles</h1>
    <p>
      I'm happy to sign a standard YC SAFE or invest through a RUV or Syndicate
      with carry of 20% or less
    </p>
  </Wrapper>
);

export default Faq;
